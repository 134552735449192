















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ComplementsCoutsSupp} from "@/utils/Enums";

type KeyValue = {
  key: string,
  value: string
}

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})

export default class ComplementsCoutsSuppModal extends Vue {
  /**
   * Defines if the modal is opened or not
   */
  @Prop({default: false}) public show!: boolean;
  /**
   * Property set when the modal is opened
   */
  @Prop({default: null}) public props!: any;

  private isSelectAllClicked: boolean = false;
  private  selectedKeys:Array<string> = [];
  private get allComplementsCoutsSuppKeys(): Array<string> {
    return Object.keys(ComplementsCoutsSupp);
  }

  private get selectedComplementsKeys(): Array<string> {
    return this.props?.rowData.complementsCoutsSuppKeys;
  }

  private set selectedComplementsKeys(value: Array<string>) {
    this.props.rowData.complementsCoutsSuppKeys = value;
  }


  /**
   * #######
   * Methods
   * #######
   */
  private getComplementTranslationByKey(key: string) {
    return this.$t(`ComplementsCoutsSuppAdministration.${key}`).toString();
  }

  private cancel() {
    this.$emit("close");
  }

  private validate() {
    this.selectedComplementsKeys = this.selectedKeys;
    this.$emit("validate", this.props.rowData);
  }

  private selectAll() {
    if (this.isSelectAllClicked) {
      this.selectedKeys = [];
    } else {
      this.selectedKeys = [...this.allComplementsCoutsSuppKeys];
    }
    this.isSelectAllClicked = !this.isSelectAllClicked;
  }
  @Watch("show")
  private onShowChange(value: boolean, oldValue: boolean) {
    if (!value) {
      this.selectedKeys = []
      return;
    }
    this.isSelectAllClicked = (this.selectedComplementsKeys.length === this.allComplementsCoutsSuppKeys.length);
    this.selectedKeys = this.selectedComplementsKeys;
  }

}
